/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wall': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 3a3 3 0 016 0v.266a3 3 0 01-6 0V3zM0 11.863a3 3 0 016 0v.266a3 3 0 01-6 0v-.266zM0 20.732a3 3 0 116 0v.266a3 3 0 01-6 0v-.266zM9 3a3 3 0 016 0v.266a3 3 0 01-6 0V3zM9 11.863a3 3 0 016 0v.266a3 3 0 01-6 0v-.266zM9 20.732a3 3 0 116 0v.266a3 3 0 01-6 0v-.266zM18 3a3 3 0 116 0v.266a3 3 0 11-6 0V3zM18 11.863a3 3 0 016 0v.266a3 3 0 11-6 0v-.266zM18 20.732a3 3 0 116 0v.266a3 3 0 11-6 0v-.266z" _fill="#898B9B"/>'
  }
})
